import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { CheckboxDefaultRenderingComponent } from './checkbox-renderings/checkbox-default-rendering.component';
import { CheckboxComponent } from './checkbox.component';


@NgModule({
  declarations: [CheckboxComponent, CheckboxDefaultRenderingComponent],
  imports: [
    CommonModule,
    FormsModule,
    I18nModule
  ],
  exports: [CheckboxComponent]
})
export class CheckboxModule { }
